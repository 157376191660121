import Appsignal from '@appsignal/javascript'
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator'
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events'
import { AppConfig } from 'vue'

/**
 * Global instance of Appsignal use it to send errors
 * @link to docs https://docs.appsignal.com/front-end/error-handling.html
 */
export const appsignal = new Appsignal({
  key: window.App?.ENV.APPSIGNAL_FRONTEND_API_KEY,
  revision: window.App?.ENV.REVISION,
  namespace: 'frontend',
})

appsignal.use(pluginWindowEvents())
appsignal.use(pluginPathDecorator())

export const createAppsignalErrorHandler =
  (): AppConfig['errorHandler'] => async (error, vm, info) => {
    // log error every time besides production
    if (window.App?.ENV.APPSIGNAL_APP_ENV !== 'production') {
      console.error(error)
    }

    // send error to appsignal
    const span = appsignal.createSpan()
    span
      .setAction('vue global error handler')
      .setTags({ framework: 'Vue', info })
      .setError(error as Error)
    await appsignal.send(span)
  }
